import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter, useRouteError, Outlet } from 'react-router-dom';
import { TitleProvider } from '@arema/components/Hooks'
import { Header, Toast } from 'react-frontier';
import { Login } from './screens'
import { LoginType } from './AuthClasses';
import { AuthProvider } from './AuthHooks';

import 'fomantic-ui-css/semantic.css';
import 'frontier-css/css/frontier.css';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

var ErrorElement = ()=>{
	const error = (useRouteError() as any);
	return <Header
		text={error.status}
		subtext={error.status===404 ? 'Página no encontrada' : `Hubo un error inesperado. (PBL-LCL-${error.status})`}
		style={{ fontSize: 100 }}
		subheaderStyle={{ fontSize: 20 }}
		containerStyle={{ marginTop: 20 }}
	/>
}

var SiteContainer = (props: { outlet: JSX.Element })=>{
	return <TitleProvider suffix='AREMA Ticket'>
		<AuthProvider>
			{props.outlet}
		</AuthProvider>
	</TitleProvider>
}

const Router = createBrowserRouter([{
	path: '/',
	errorElement: <SiteContainer outlet={<ErrorElement />} />,
	element: <SiteContainer outlet={<Outlet />} />,
	children: [
		{ path: '/admin', element: <Login type={LoginType.ADMIN} /> },
		{ path: '/external', element: <Login type={LoginType.EXTERNAL} /> },
	]
}]);

var Root = ()=>{
	return <Toast.Provider>
		<RouterProvider router={Router} />
	</Toast.Provider>
}

root.render(<Root />);