import React, { useEffect, useRef, useState } from 'react';
import { AuthTokens, ChangePasswordData, LoginType } from '../AuthClasses';
import { Button, Groupper, Header, Input, Message, Checkbox, Toast } from 'react-frontier';
import { useValidator } from '@arema/components/Hooks';
import { Link, useSearchParams } from 'react-router-dom';
import { useAuth } from '../AuthHooks';
import { LoginSource } from '@arema/components/Classes';
import API, { CAPTCHA_ACTIVE, CDN_ROOT, RECAPTCHA_KEY } from '../AuthAPI';
import ReCAPTCHA from 'react-google-recaptcha';
import classNames from 'classnames';

import '../scss/login.scss';

interface LoginProps{
	type: LoginType
}

enum LoginMode{
	LOGIN = 1,
	FORGOT = 2,
	TOTP = 3,
	CHANGE = 4,
}

interface LoginForm{
	email: string,
	forgot_email: string,
	password: string,
	totp: string,
	code: string,
	captcha: string,
	remember: boolean,
}

interface ChangePasswordForm{
	current_password: string,
	new_password: string,
	repeat_password: string,
}

var Login = (props: LoginProps)=>{
	var recaptchaRef = useRef<ReCAPTCHA>(null);
	var [query] = useSearchParams();
	var [mode, setMode] = useState<LoginMode>(LoginMode.LOGIN);
	var [sending, setSending] = useState<boolean>(false);
	var [loggedIn, setLoggedIn] = useState<boolean>(false);
	var [loginError, setLoginError] = useState<string>(null);
	var [forgotSent, setForgotSent] = useState<boolean>(false);
	var [changeData, setChangeData] = useState<ChangePasswordData>(null);
	var { admin, admin_saved, external, external_saved, clearSavedAdmin, loginSavedAdmin, clearSavedExternal, loginSavedExternal } = useAuth();
	var ChangeForm = useValidator<ChangePasswordForm>({
		current_password: '',
		new_password: '',
		repeat_password: '',
	}, {
		current_password: [{ rule: 'minLength', params: [5] }],
		new_password: [{ rule: 'password' }],
		repeat_password: [{ rule: 'matches', params: ['new_password'], prompt: 'Las contraseñas no concuerdan' }],
	});
 	var { data, reset: resetData, errors, validate, onDataChange, prompts, setPrompts } = useValidator<LoginForm>({
		email: '',
		forgot_email: '',
		password: '',
		totp: '',
		code: '',
		captcha: null,
		remember: false,
	}, {
		email: [{
			rule: props.type===LoginType.ADMIN ? 'minLength' : 'email',
			params: [5],
			prompt: `El ${props.type===LoginType.ADMIN ? 'usuario' : 'correo electrónico'} no es válido`
		}],
	});

	var login_source = parseInt(query.get('s'));
	var pdv_token = query.get('pdv');
	var is_pdv = pdv_token && pdv_token.length>10;
	var continue_url = decodeURIComponent(query.get('continue') || '');
	var params_valid = Object.values(LoginSource).indexOf(login_source)!=-1 && !!continue_url && continue_url.length>=10 && (!pdv_token || pdv_token.length>10);

	var getButtonText = ()=>{
		switch(mode){
			case LoginMode.LOGIN: return 'Iniciar sesión'
			default: return 'Continuar';
		}
	}

	var getLoginName = ()=>{
		switch(props.type){
			case LoginType.ADMIN: return 'Administrador';
			case LoginType.EXTERNAL: return 'Empresarios';
			default: return null;
		}
	}

	var getContinueUrl = (tokens: AuthTokens)=>{
		if(!continue_url || !tokens) return null;
		continue_url = continue_url.replace(/\/$/gi, '');
		var params : any = {
			at: tokens.access,
			e: tokens.expires,
			lt: tokens.type,
		}
		if(tokens.refresh && [LoginSource.PDV_APP, LoginSource.SCANNER_APP].indexOf(login_source)!=-1){
			params.rt = tokens.refresh;
			params.re = tokens.refresh_expires;
		}
		var redirect_url = query.get('redirect');
		if(redirect_url && redirect_url.length>=10){
			params.r = redirect_url;
		}
		return `${continue_url}?${Object.keys(params).map(a=>`${a}=${params[a]}`).join('&')}`
	}

	var refreshAdmin = ()=>{
		if(!admin_saved) return;
		setSending(true);
		loginSavedAdmin().then(res=>{
			if(res.error) return setLoginError(res.message);
			if(!res.data.tokens?.access){
				return setLoginError(`Hubo un error inesperado iniciando sesión (LCL-SVD${props.type || 'X'}-2)`);
			}
			setLoggedIn(true);
			setTimeout(()=>{
				window.location.href = getContinueUrl(res.data.tokens);
			}, 1000);
		}).catch(err=>{
			setLoginError(`Hubo un error inesperado haciendo login. (LCL-SVD${props.type || 'X'}-1)`);
		}).finally(()=>{
			setSending(false);
		})
	}

	var refreshExternal = ()=>{
		if(!external_saved) return;
		setSending(true);
		loginSavedExternal().then(res=>{
			if(res.error) return setLoginError(res.message);
			if(!res.data.tokens?.access){
				return setLoginError(`Hubo un error inesperado iniciando sesión (LCL-SVD${props.type || 'X'}-2)`);
			}
			setLoggedIn(true);
			setTimeout(()=>{
				window.location.href = getContinueUrl(res.data.tokens);
			}, 1000);
		}).catch(err=>{
			setLoginError(`Hubo un error inesperado haciendo login. (LCL-SVD${props.type || 'X'}-1)`);
		}).finally(()=>{
			setSending(false);
		})
	}

	var refreshAuth = ()=>{
		if(props.type===LoginType.ADMIN){
			return refreshAdmin();
		}else if(props.type===LoginType.EXTERNAL){
			return refreshExternal();
		}
	}

	var submitAdminLogin = (captcha: string)=>{
		if(Number.isNaN(login_source)){
			login_source = LoginSource.GENERIC
		}
		
		setSending(true);
		API.loginAdmin(data.email, data.password, login_source, captcha, data.remember, data.remember ? (data.code || null) : null, is_pdv ? pdv_token : null).then(res=>{
			if(res.error) return setPrompts([res.message]);
			if(!res.data.tokens?.access){
				return setPrompts(['Hubo un error inesperado iniciando sesión (LCL-ADMLG-2)']);
			}
			if(res.data.user.change_password){
				setChangeData({
					type: LoginType.ADMIN,
					user: {
						admin_id: res.data.user.admin_id,
						email: res.data.user.email,
					},
					token: res.data.tokens.access
				});
				resetData();
				return setMode(LoginMode.CHANGE);
			}
			setLoggedIn(true);
			setTimeout(()=>{
				window.location.href = getContinueUrl(res.data.tokens);
			}, 1000);
		}).catch(err=>{
			setPrompts(['Hubo un error inesperado iniciando sesión (LCL-ADMLG-1)']);
		}).finally(()=>{
			setSending(false);
		});
	}

	var submitExternalLogin = (captcha: string)=>{
		setSending(true);
		API.loginExternal(data.email, data.password, captcha, data.remember).then(res=>{
			if(res.error) return setPrompts([res.message]);
			if(!res.data.tokens?.access){
				return setPrompts(['Hubo un error inesperado iniciando sesión (LCL-EXLG-2)']);
			}
			if(res.data.user.change_password){
				setChangeData({
					type: LoginType.EXTERNAL,
					user: {
						external_id: res.data.user.external_id,
						email: res.data.user.email,
					},
					token: res.data.tokens.access
				});
				resetData();
				return setMode(LoginMode.CHANGE);
			}
			setLoggedIn(true);
			setTimeout(()=>{
				window.location.href = getContinueUrl(res.data.tokens);
			}, 1000);
		}).catch(err=>{
			setPrompts(['Hubo un error inesperado iniciando sesión (LCL-EXLG-1)']);
		}).finally(()=>{
			setSending(false);
		});
	}

	var submitAdminForgot = (captcha: string)=>{
		var { valid } = validate({ captcha }, {
			email: [{
				rule: 'email', prompt: 'El correo electrónico no es válido',
			}]
		});
		if(!valid) return;
		setPrompts(null);

		setSending(true);
		API.sendAdminForgot(data.email, captcha).then(res=>{
			if(res.error) return setPrompts([res.message]);
			
		}).catch(err=>{
			setPrompts(['Hubo un error haciendo la solicitud de contraseña. (LCL-ADMFG-1)']);
		}).finally(()=>{
			setSending(false);
		});
	}

	var submitExternalForgot = (captcha: string)=>{
		setSending(true);
		API.sendExternalForgot(data.email, captcha).then(res=>{
			if(res.error) return setPrompts([res.message]);
		}).catch(err=>{
			setPrompts(['Hubo un error haciendo la solicitud de contraseña. (LCL-EXTFG-1)']);
		}).finally(()=>{
			setSending(false);
		});
	}

	var clearSaved = ()=>{
		if(props.type===LoginType.ADMIN){
			return clearSavedAdmin();
		}else if(props.type===LoginType.EXTERNAL){
			return clearSavedExternal();
		}
	}

	var changeLoginType = ()=>{
		window.location.href = `${window.location.origin}/${props.type===LoginType.ADMIN ? 'external' : 'admin'}${window.location.search}`;
	}

	var submitChange = ()=>{
		var { valid } = ChangeForm.validate()
		if(!valid) return;

		setSending(true);
		setPrompts(null);
		API.changePassword(changeData.type, ChangeForm.data.current_password, ChangeForm.data.new_password, changeData.token).then(res=>{
			if(res.error) return setPrompts([res.message]);
			ChangeForm.reset();
			resetData();
			setMode(LoginMode.LOGIN);
			Toast.success('Se ha cambiado la contraseña, favor de iniciar sesión de nuevo.')
		}).catch(err=>{
			setPrompts(['Hubo un error inesperado cambiando la contraseña (LCL-CHPWD-1)']);
		}).finally(()=>{
			setSending(false);
		});
	}

	var submit = async ()=>{
		if(mode===LoginMode.CHANGE) return submitChange();

		var { valid } = validate(null, {
			password: [{
				rule: 'minLength', params: [5], prompt: 'La contraseña debe de ser de mínimo 5 caracteres', if: mode===LoginMode.LOGIN
			}],
			code: [{
				rule: /[0-9]{4}/gi, prompt: 'El código debe de ser un numerico de 4 digitos', if: (is_pdv && data.remember)
			}]
		});
		if(!valid) return;

		var cph : string = null;
		if(CAPTCHA_ACTIVE){
			if(!data.captcha){
				try{
					setSending(true);
					recaptchaRef.current.reset();
					var cph = await recaptchaRef.current.executeAsync()
					if(!cph) return setPrompts(['Hubo un error realizando el captcha (LCL-ADMSB-1)']);
					onDataChange('captcha')(cph);
				}catch(e){
					return setPrompts(['Hubo un error realizando el captcha (LCL-ADMSB-2)']);
				}finally{
					setSending(false);
				}
			}else{
				cph = data.captcha;
			}
		}
		
		switch(mode){
			case LoginMode.LOGIN: 
				if(props.type===LoginType.ADMIN){
					return submitAdminLogin(cph);
				}else if(props.type===LoginType.EXTERNAL){
					return submitExternalLogin(cph);
				}
			break;
			case LoginMode.FORGOT:
				if(props.type===LoginType.ADMIN){
					return submitAdminForgot(cph);
				}else if(props.type===LoginType.EXTERNAL){
					return submitExternalForgot(cph);
				}
			break;
		}
	}

	var returnMode = ()=>{
		resetData();
		setChangeData(null);
		setMode(LoginMode.LOGIN);
	}

	var logged_in = loggedIn && (props.type===LoginType.ADMIN ? !!admin : (props.type===LoginType.EXTERNAL ? !!external : null));
	var login_saved = !is_pdv && (props.type===LoginType.ADMIN ? (!!admin && admin_saved) : (props.type===LoginType.EXTERNAL ? (!!external && external_saved) : false));
	var login_data : { user: { first_name: string, last_name: string }, tokens: AuthTokens } = props.type===LoginType.ADMIN ? admin : (props.type===LoginType.EXTERNAL ? external : null);
	
	return <div className={classNames("ar login-screen", {
		admin: props.type===LoginType.ADMIN,
		change: mode===LoginMode.CHANGE
	})}>
		<Groupper defaultStyle={false} actions={params_valid && !logged_in && !login_saved && !forgotSent ? <>
			{(mode===LoginMode.TOTP || mode===LoginMode.FORGOT || mode===LoginMode.CHANGE) && (
				<Button basic text={'Regresar'} onClick={returnMode} />
			)}
			<Button color='black' text={getButtonText()} onClick={submit} loading={sending} />	
		</> : null}>
			<div className="head logo">
				<img src={`${CDN_ROOT}/assets/logo/Logo${props.type===LoginType.ADMIN ? 'W' : ''}H_Large.webp`} alt="AREMA" />
			</div>
			{logged_in ? (
				<div className="contents full">
					<Header
						iconName='check-circle' 
						iconStyle={{ color: '#1a9461', marginBottom: 10 }} 
						text={`¡Hola, ${(login_data?.user.first_name || 'Usuario')}!`} 
						subtext='Te redireccionaremos pronto a tu destino' 
						subheaderStyle={{ marginTop: 5 }}
						actions={(
							<Button text='Continuar' size='tiny' style={{ padding: '2px 10px', minWidth: 100 }} basic color='green' as={Link} to={getContinueUrl(login_data?.tokens)} />
						)}
					/>
				</div>
			) : params_valid ? <>
				<Groupper.Divider centered top>
					<Header centered text={getLoginName()} subtext={mode===LoginMode.FORGOT ? 'Olvidé mi contraseña' : null} />
				</Groupper.Divider>
				{(login_saved && login_data) ? (
					<div className="contents full semi">
						{loginError ? <>
							<Header iconName='exclamation-triangle' iconStyle={{ fontSize: 70, marginBottom: 15 }} text='Error' subtext={loginError} />
							<Button text='Regresar' style={{ marginTop: 15, minWidth: 200 }} onClick={()=>setLoginError(null)} loading={false} />
						</> : <>
							<Header
								iconName='user-circle' 
								iconStyle={{ marginBottom: 10 }} 
								text={`${login_data.user.first_name} ${login_data.user.last_name}`} 
								subtext='Guardamos tu sesión'
							/>
							<Button text='Iniciar sesión' style={{ marginTop: 15, minWidth: 200 }} color='orange' onClick={refreshAuth} loading={sending} />
							<Button text='Cerrar sesión' size='tiny' style={{ marginTop: 5, minWidth: 120 }} basic color='black' onClick={clearSaved} disabled={sending} />
						</>}
					</div>
				)  : forgotSent ? (
					<div className="contents semi full">
						<Header 
							iconName='envelope' 
							iconStyle={{ fontSize: 70, marginBottom: 10 }} 
							text='Enlace enviado' 
							subtext={`Si existe una cuenta de AREMA${props.type===LoginType.ADMIN ? ' Administrador' : ''} con este correo electrónico, te enviamos un correo con instrucciones para restablecer tu contraseña.`} 
							subheaderStyle={{ lineHeight: '16px' }} 
						/>
						<Button text='Iniciar sesión' color='black' style={{ marginTop: 20, minWidth: 200 }} onClick={()=>{
							setForgotSent(null);
							setMode(LoginMode.LOGIN);
						}} loading={false} />
					</div>
				) : (
					<div className="contents">
						{mode===LoginMode.LOGIN ? <>
							<Input label={props.type===LoginType.ADMIN ? 'Usuario' : 'Correo electrónico'} error={errors.email} value={data.email} onChange={onDataChange('email')} submitOnEnter onSubmit={submit} />
							<Input label='Contraseña' style={{ marginBottom: 0 }} error={errors.password} value={data.password} onChange={onDataChange('password')} inputType='password' submitOnEnter onSubmit={submit} />
							<Checkbox label={'Recordar mi sesión'} style={{ marginTop: 15 }} checked={data.remember} onChange={onDataChange('remember')} color='orange' />
							{(is_pdv && data.remember) && (
								<Input label='Código de acceso' comment='Usarás este código numérico para iniciar sesión rápidamente en el PDV' value={data.code} onChange={onDataChange('code')} labelStyle={{ textAlign: 'center' }} commentStyle={{ textAlign: 'center' }} style={{ marginTop: 20 }} inputStyle={{ textAlign: 'center', fontSize: 20 }} inputType='password' maxLength={4} />
							)}
							<div className="forgot">
								<Button basic size='tiny' text='Olvidé mi contraseña' color='black' onClick={()=>setMode(LoginMode.FORGOT)} />
							</div>
						</> : mode===LoginMode.FORGOT ? <>
							<Message centered type='info' text={`Ingresa el correo electrónico de tu cuenta de AREMA ${props.type===LoginType.ADMIN ? 'Administrador' : ''}`} style={{ marginBottom: 15 }} />
							<Input label={'Correo electrónico'} error={errors.email} value={data.email} onChange={onDataChange('email')} />
						</> : mode===LoginMode.CHANGE ? <>
							<Message style={{ marginBottom: 15 }} type="info" text="La contraseña de AREMA de esta cuenta ha sido marcada como expirada. Favor de ingresar una nueva contraseña" list={[
								`Cuenta: ${changeData.user.admin_id ? 'Administrador' : 'Externo'}`,
								`Usuario: ${changeData.user.email}`,
								'Por seguridad, la contraseña debe de ser de minimo 8 caracteres y debe de tener por lo menos: 1 número, 1 letra mayúscula, 1 letra minúscula y 1 caracter especial (!@#$%^&*.,+).'
							]} />
							<Input label='Contraseña actual' error={ChangeForm.errors.current_password} value={ChangeForm.data.current_password} onChange={ChangeForm.onDataChange('current_password')} inputType='password' />
							<Input label='Nueva contraseña' error={ChangeForm.errors.new_password} value={ChangeForm.data.new_password} onChange={ChangeForm.onDataChange('new_password')} inputType='password' />
							<Input label='Repetir nueva contraseña' error={ChangeForm.errors.repeat_password} value={ChangeForm.data.repeat_password} onChange={ChangeForm.onDataChange('repeat_password')} inputType='password' />
						</> : null}
						<Message type='error' list={prompts?.length>0 ? prompts : ChangeForm.prompts} style={{ marginTop: 5 }} />
					</div>
				)}
			</> : (
				<div className="contents full">
					<Header iconName='exclamation-triangle' iconStyle={{ fontSize: 70, marginBottom: 15 }} text='Autenticación inválida' subtext='El la autenticación de usuario fue mal configurada' />
				</div>
			)}
			{CAPTCHA_ACTIVE && (
				<ReCAPTCHA 
					hl='es-419'
					size='invisible'
					sitekey={RECAPTCHA_KEY}
					ref={recaptchaRef}
					onChange={onDataChange('captcha')}
					onExpired={()=>onDataChange('captcha')(null)}
				/>
			)}
		</Groupper>
		{query.get('ac')=='1' && (
			<Button basic text={`Acceso ${props.type===LoginType.ADMIN ? 'empresario' : 'administrador'}`} iconName={props.type===LoginType.ADMIN ? 'user-tie' : 'user-cog'} color='white' style={{ marginTop: 10 }} onClick={changeLoginType} />
		)}
	</div>
}

export default Login;