import React, { useEffect, useState, createContext, useContext, PropsWithChildren } from 'react';
import { ADMIN_AUTH_EVENT, ADMIN_AUTH_KEY, AdminLogin, EXTERNAL_AUTH_EVENT, EXTERNAL_AUTH_KEY, ExternalLogin } from './AuthClasses';
import { APIResponse } from '@arema/components/Classes';
import API from './AuthAPI';
import moment from 'moment';

const EXPIRED_CODES = [
	'P-TKNV', 'P-RFTKNV', 'P-TKNE',
]

var AuthContext = createContext<{
	admin: AdminLogin,
	admin_saved: boolean,
	loginSavedAdmin: ()=>Promise<APIResponse<AdminLogin>>,
	clearSavedAdmin: ()=>void,

	external: ExternalLogin
	external_saved: boolean,
	loginSavedExternal: ()=>Promise<APIResponse<ExternalLogin>>,
	clearSavedExternal: ()=>void,
}>({
	admin: null,
	admin_saved: false,
	loginSavedAdmin: ()=>null,
	clearSavedAdmin: ()=>null,

	external: null,
	external_saved: false,
	loginSavedExternal: ()=>null,
	clearSavedExternal: ()=>null,
});
var useAuth = () => useContext(AuthContext);
var AuthProvider = (props: PropsWithChildren)=>{
	var [admin, setAdmin] = useState<AdminLogin>(null);
	var [external, setExternal] = useState<ExternalLogin>(null);

	useEffect(()=>{
		if(admin===null){
			onLogin()
		}
		window.addEventListener(ADMIN_AUTH_EVENT, onLogin);
		return ()=>{
			window.removeEventListener(ADMIN_AUTH_EVENT, onLogin);
		}
	}, [admin]);

	useEffect(()=>{
		if(external===null){
			onExternalLogin();
		}
		window.addEventListener(EXTERNAL_AUTH_EVENT, onExternalLogin);
		return ()=>{
			window.removeEventListener(EXTERNAL_AUTH_EVENT, onExternalLogin);
		}
	}, [external]);

	var onLogin = ()=>{
		var login = window.localStorage.getItem(ADMIN_AUTH_KEY);
		try{
			var login_data = JSON.parse(login) as AdminLogin;
			var now = moment().unix();
			setAdmin(login_data);
			if(login_data.tokens.refresh_expires<=(now+30)){
				window.localStorage.removeItem(ADMIN_AUTH_KEY);
			}
		}catch(e){}
	}

	var onExternalLogin = ()=>{
		var login = window.localStorage.getItem(EXTERNAL_AUTH_KEY);
		try{
			var login_data = JSON.parse(login) as ExternalLogin;
			var now = moment().unix();
			setExternal(login_data);
			if(login_data.tokens.refresh_expires<=(now+30)){
				window.localStorage.removeItem(EXTERNAL_AUTH_KEY);
			}
		}catch(e){}
	}

	var loginSavedAdmin = () : Promise<APIResponse<AdminLogin>>=>{
		if(!admin || !admin.tokens) return Promise.resolve({
			error: true,
			code: 'LCL-EXTSV-1',
			message: 'No existe una autenticación válida (LCL-EXTSV-1)'
		})
		return new Promise((resolve, reject)=>{
			API.loginRefreshAdmin(admin.tokens.refresh).then(res=>{
				if(res.error && EXPIRED_CODES.indexOf(res.code)!==-1){
					setAdmin(null);
				}
				return resolve(res);
			}).catch(reject);
		})
	}

	var clearSavedAdmin = ()=>{
		window.localStorage.removeItem(ADMIN_AUTH_KEY);
		setAdmin(null);
	}

	var loginSavedExternal = () : Promise<APIResponse<ExternalLogin>>=>{
		if(!external || !external.tokens) return Promise.resolve({
			error: true,
			code: 'LCL-EXTSV-1',
			message: 'No existe una autenticación válida (LCL-EXTSV-1)'
		})
		return new Promise((resolve, reject)=>{
			API.loginRefreshExternal(external.tokens.refresh).then(res=>{
				if(res.error && EXPIRED_CODES.indexOf(res.code)!==-1){
					setExternal(null);
				}
				return resolve(res);
			}).catch(reject);
		});
	}

	var clearSavedExternal = ()=>{
		window.localStorage.removeItem(EXTERNAL_AUTH_KEY);
		setExternal(null);
	}
	
	var now = moment().unix();
	
	return <AuthContext.Provider value={{
		admin,
		admin_saved: admin && admin.tokens?.refresh && admin.tokens.refresh_expires>(now+30),
		loginSavedAdmin,
		clearSavedAdmin,

		external,
		external_saved: external && external.tokens?.refresh && external.tokens.refresh_expires>(now+30),
		loginSavedExternal,
		clearSavedExternal,
	}}>
		{props.children}
	</AuthContext.Provider>
}

export {
	useAuth,
	AuthProvider,
}